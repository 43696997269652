<template>
  <div class="content-wrapper profile">
    <section-wrapper>
      <template #header>
        <div class="section-wrapper__header__left">Profile</div>
        <div class="section-wrapper__header__right">
          <div
            v-if="!loading"
            v-b-tooltip.hover
            :title="!$c_hasChanges ? 'You have not made any modifications to your profile.' : ''"
          >
            <b-btn
              class="tracker-primary-button"
              :disabled="!$c_hasChanges || updatingDetails"
              @click="updateDetails"
            >
              <i v-if="updatingDetails" class="fa fa-spinner fa-spin mr-2"></i>
              Update Details
            </b-btn>
          </div>
        </div>
      </template>
      <div
        v-if="loading"
        class="loading mb-4 p-5"
      >
        <loading-skeleton class="mt-4" type="form" v-for="i in 4" :key="i" />
      </div>
      <div v-else class="profile__form">
        <div class="profile-info">
          <!-- Circular profile with name and lastname initials -->

          <!-- Cool looking blue background with animations of irregular figures -->
          <div style="position: relative" class="profile-info__background">

            <svg style="position: absolute; bottom: 0" width="380" height="50" viewBox="0 0 380 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M192.745 0C124.43 0 29.8876 33.3333 0 50H380L378.951 49.5613C338.775 32.756 260.465 0 192.745 0Z" fill="url(#paint0_linear_535_3773)" fill-opacity="0.5" />
              <defs>
                <linearGradient id="paint0_linear_535_3773" x1="190" y1="0" x2="190" y2="50" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#78E7FF" />
                  <stop offset="1" stop-color="#26C0E2" />
                </linearGradient>
              </defs>
            </svg>


            <svg style="position: absolute; top: 0; right: 4%" width="380" height="50" viewBox="0 0 380 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M187.255 50C255.57 50 350.112 16.6667 380 0L-4.37114e-06 3.32207e-05L1.04876 0.438693C41.2253 17.2441 119.535 50 187.255 50Z" fill="url(#paint0_linear_535_3771)" fill-opacity="0.5" />
              <defs>
                <linearGradient id="paint0_linear_535_3771" x1="190" y1="50" x2="190" y2="1.66103e-05" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#78E7FF" />
                  <stop offset="1" stop-color="#26C0E2" />
                </linearGradient>
              </defs>
            </svg>


            <svg style="position: absolute; bottom: 0; right: 25%" width="174" height="23" viewBox="0 0 174 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M88.2568 0C56.9759 0 13.6854 15.3333 0 23L174 23L173.52 22.7982C155.123 15.0678 119.266 0 88.2568 0Z" fill="url(#paint0_linear_535_3774)" fill-opacity="0.5" />
              <defs>
                <linearGradient id="paint0_linear_535_3774" x1="87" y1="0" x2="87" y2="23" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#78E7FF" />
                  <stop offset="1" stop-color="#26C0E2" />
                </linearGradient>
              </defs>
            </svg>


            <svg style="position: absolute; top: 0; left: 25%" width="174" height="23" viewBox="0 0 174 23" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M85.7432 23C117.024 23 160.315 7.66666 174 -1.52588e-05L-2.01073e-06 -4.72259e-08L0.480223 0.201784C18.8768 7.93226 54.7344 23 85.7432 23Z" fill="url(#paint0_linear_535_3772)" fill-opacity="0.5" />
              <defs>
                <linearGradient id="paint0_linear_535_3772" x1="87" y1="23" x2="87" y2="-7.65301e-06" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#78E7FF" />
                  <stop offset="1" stop-color="#26C0E2" />
                </linearGradient>
              </defs>
            </svg>
          </div>


          <div class="profile-info__avatar">
            <div class="profile-info__avatar__wrapper">
              <span>{{ $c_initials }}</span>
            </div>
          </div>
        </div>

        <div class="profile__form__body">
          <div class="first-last-name">
            <b-form-group
              id="firstname"
              class="tracker-form-group"
              label="Firstname"
              :invalid-feedback="$_getError('firstname')"
              :state="$_hasError('firstname')"
            >
              <b-form-input
                class="tracker-form-input"
                data-vv-as="Firstname"
                :state="$_hasError('firstname')"
                v-validate="'alpha|required'"
                type="text"
                v-model="firstname"
                name="firstname"
                key="firstname"
                placeholder="Firstname"
              />
            </b-form-group>

            <!-- Lastname -->
            <b-form-group
              class="tracker-form-group"
              label="Lastname"
              id="lastname"
              :invalid-feedback="$_getError('lastname')"
              :state="$_hasError('lastname')"
            >
              <b-form-input
                class="tracker-form-input"
                data-vv-as="Lastname"
                :state="$_hasError('lastname')"
                v-validate="'alpha'"
                type="text"
                v-model="lastname"
                name="lastname"
                key="lastname"
                placeholder="Lastname"
              />
            </b-form-group>
          </div>

          <b-form-group
            class="tracker-form-group"
            label="Username"
            :invalid-feedback="$_getError('username')"
            :state="$_hasError('username')"
          >
            <b-form-input
              class="tracker-form-input"
              :disabled="true"
              data-vv-as="Username"
              :state="$_hasError('username')"
              v-validate="'alpha|required'"
              autocomplete="off"
              type="text"
              v-model="username"
              name="username"
              key="username"
              placeholder="Username"
            />
          </b-form-group>

          <!-- Email -->
          <b-form-group
            class="tracker-form-group"
            label="Email"
            :invalid-feedback="$_getError('email')"
            :state="$_hasError('email')"
          >
            <b-form-input
              class="tracker-form-input"
              data-vv-as="Email"
              :disabled="true"
              :state="$_hasError('email')"
              v-validate="'email|required'"
              type="text"
              v-model="email"
              name="email"
              key="email"
              placeholder="Email"
            />
          </b-form-group>

          <!-- Password -->
          <div class="password">
            <b-form-group
              class="tracker-form-group"
              label="Password"
            >
              <b-form-input
                :disabled="true"
                class="tracker-form-input"
                type="password"
                name="password"
                key="password"
                value="••••••••"
                placeholder="Password"
              />
            </b-form-group>

            <b-btn :disabled="updatingDetails" @click="$_showUpdatePasswordModal" class="tracker-primary-button tracker-primary-button--outline">Update Password</b-btn>
          </div>
        </div>
      </div>
    </section-wrapper>

    <update-password-modal ref="updatePasswordModal" />
  </div>
</template>

<script>
import SectionWrapper from '@/components/Utils/Sections/SectionWrapper.vue';
import notifications from '@sh/mixins/notifications';
import UpdatePasswordModal from './components/UpdatePasswordModal.vue';

export default {
  name: 'Profile',
  components: {
    SectionWrapper,
    UpdatePasswordModal,
  },
  mixins: [notifications],
  data() {
    return {
      profile: null,
      loading: false,
      firstname: '',
      lastname: '',
      username: '',
      email: '',
      me: null,
      updatingDetails: false,
    };
  },
  computed: {
    $c_initials() {
      return `${this.me.firstname.charAt(0)}${this.me.lastname.charAt(0)}`.toUpperCase();
    },
    $c_hasChanges() {
      return this.firstname !== this.me.firstname
        || this.lastname !== this.me.lastname
        || this.username !== this.me.username
        || this.email !== this.me.email;
    },
  },
  async created() {
    this.loading = true;
    await this.$api.users.me();
    const me = await this.$api.users.me();
    this.email = me.email;
    this.firstname = me.firstname;
    this.lastname = me.lastname;
    this.username = me.username;
    this.me = me;
    this.loading = false;
  },
  methods: {
    async updateDetails() {
      try {
        if (!await this.validate()) {
          return;
        }
        this.updatingDetails = true;
        const payload = {
          firstname: this.firstname,
          lastname: this.lastname,
          // email: this.email,
        };
        await this.$api.users.updateDetails(payload);
        this.me = {
          ...this.me,
          ...payload,
        };
        this.$n_successNotification({ title: 'Profile updated successfully!' });
      } catch (err) {
        this.$n_failNotification({ title: err?.response?.data?.message || 'Failed to update your profile' });
      } finally {
        this.updatingDetails = false;
      }
    },
    async validate() {
      try {
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
    $_showUpdatePasswordModal() {
      this.$refs.updatePasswordModal.showModal();
    },
  },
};
</script>

<style lang="scss">
.profile {
  width: 90%;
  margin: 4rem auto;

  @media (min-width: 1560px) {
    width: 45%;
  }

  @media (min-width: 1366px) and (max-width: 1560px) {
    width: 60%;
  }

  .section-wrapper__body {
    padding: 0;
  }

  .small-text {
    font-size: 1.4rem;
    color: $black-300;
    /* text-align: justify; */
  }

  &__form {
    .profile-info {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      &__background {
        width: 100%;
        height: 10rem;
        background-color: #26bfe2de;
      }

      &__avatar {
        z-index: 2;
        width: 8rem;
        height: 8rem;
        margin-top: -4rem;
        border-radius: 50%;
        background-color: $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 4rem;
        font-weight: 400;
        color: $black-300;
        margin-right: 4rem;

        &__wrapper {
          width: 7rem;
          height: 7rem;
          border-radius: 50%;
          background-color: #26bfe2de;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 3rem;
          font-weight: 400;
          color: $color-white;
        }
      }
    }

    &__body {
      padding: 2rem 4rem;
      .first-last-name {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2rem;
        margin-bottom: -1rem;
        margin-top: -1rem;

        #firstname {
          margin-right: 2rem;
        }

        #lastname {
          margin-left: -2rem;
        }
      }

      .password {
        display: grid;
        grid-template-columns: 4fr 1fr;
        grid-gap: 2rem;
        align-items: center;

        .tracker-form-group {
          margin-top: 1.2rem;
        }
      }

      &__section {
        color: $black-800;
        font-size: 1.5rem;
      }

      .security-details {
        display: grid;
        grid-template-columns: 3fr 1fr;
        margin-top: 2rem;
        align-items: center;
        grid-gap: 5rem;
      }
    }
  }
}
</style>
