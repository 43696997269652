<template>
  <b-modal
    class="update-password-modal"
    v-model="modal"
    v-if="modal"
    no-close-on-backdrop
    modal-class="tracker-modal update-password-modal tracker-modal--scrollable"
    no-fade
    size="md"
    centered
    @hidden="$_hidden"
  >
    <!-- Modal Header -->
    <template slot="modal-header">
      <span></span>
      <h2 class="modal-header__title">Update Password</h2>

      <span class="modal-header__close-icon-wrapper" @click="hideModal">
        <svg class="modal-header__close-icon" width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="12.5059" y="4.021" width="2" height="12" transform="rotate(45 12.5059 4.021)"
                fill="#CCD2E3"
          />
          <rect x="13.9199" y="12.5063" width="2" height="12"
                transform="rotate(135 13.9199 12.5063)" fill="#CCD2E3"
          />
        </svg>
      </span>
    </template>

    <div class="modal-body">
      <!-- Current Password -->
      <b-form-group
        class="tracker-form-group"
        label="Current Password"
        style="margin-top: 1.5rem"
        :invalid-feedback="$_getError('current_password')"
        :state="$_hasError('current_password')"
      >
        <b-form-input
          class="tracker-form-input"
          data-vv-as="Current Password"
          :state="$_hasError('current_password')"
          v-validate="'required'"
          type="password"
          v-model="currentPassword"
          name="current_password"
          key="current_password"
          placeholder="Current Password"
        />
      </b-form-group>

      <!-- New Password -->
      <b-form-group
        class="tracker-form-group"
        label="New Password"
        :invalid-feedback="$_getError('new_password')"
        :state="$_hasError('new_password')"
      >
        <b-form-input
          class="tracker-form-input"
          data-vv-as="New Password"
          :state="$_hasError('new_password')"
          v-validate="'required|min:8'"
          type="password"
          v-model="newPassword"
          name="new_password"
          key="new_password"
          placeholder="New Password"
          ref="new_password"
        />
      </b-form-group>

      <!-- Repeat New Password -->
      <b-form-group
        class="tracker-form-group"
        label="Repeat New Password"
        :invalid-feedback="$_getError('repeat_new_password')"
        :state="$_hasError('repeat_new_password')"
      >
        <b-form-input
          class="tracker-form-input"
          data-vv-as="Repeat New Password"
          :state="$_hasError('repeat_new_password')"
          v-validate="'required|confirmed:new_password|min:8'"
          type="password"
          v-model="repeatNewPassword"
          name="repeat_new_password"
          key="repeat_new_password"
          placeholder="Repeat New Password"
        />
      </b-form-group>
    </div>

    <template slot="modal-footer">
      <b-btn :disabled="loadingStatus" class="tracker-primary-button" @click="submit">
        <template v-if="loadingStatus"><i class="fa fa-spinner fa-spin mr-2"></i>
        </template>
        Update
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import notifications from '@sh/mixins/notifications';

export default {
  name: 'UpdatePasswordModal',
  mixins: [notifications],
  data() {
    return {
      modal: false,
      loadingStatus: false,
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    };
  },
  methods: {
    async submit() {
      if (!await this.validate()) return;

      try {
        this.loadingStatus = true;
        const payload = {
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        };
        await this.$api.users.updatePassword(payload);
        this.hideModal();
        this.$n_successNotification({ title: 'Password updated successfully!' });
      } catch (err) {
        this.$n_failNotification({ title: err?.response?.data?.message || 'Failed to update password' });
      } finally {
        this.loadingStatus = false;
      }
    },
    $_hidden() {
      this.modal = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.repeatNewPassword = '';
    },
    hideModal() {
      this.$_hidden();
    },
    showModal() {
      this.modal = true;
    },
    async validate() {
      try {
        return await this.$validator.validateAll();
      } catch (error) {
        return false;
      }
    },
    $_hasError(name) {
      return this.$validator.errors.has(name) ? false : null;
    },
    $_getError(name) {
      return this.$validator.errors.first(name);
    },
    $_removeError(name) {
      this.$validator.errors.remove(name);
    },
  },
};
</script>
